import { useNavigate } from 'react-router-dom';
import './Header.css';
import React, { useState } from 'react';

const Header: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  const navigateTo = (page: string) => {
    navigate(page);
    setMenuOpen(false);
  };

  return (
    <header>
      <div className={`header container ${menuOpen ? 'is-open' : ''}`}>
        <div className="header__logo" onClick={() => navigateTo('/')}>
          <img src="/images/global/logo.svg" alt="" />
        </div>
        <div onClick={() => setMenuOpen(!menuOpen)} className={`header-menu ${menuOpen ? 'is-open' : ''}`}>
          <div className="header-menu-button" >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
      <div className={`menu ${menuOpen ? 'is-open' : ''}`}>
        <div className="menu-overlay"></div>
        <div className="menu-content flex flex--direction__column">
          <a onClick={() => navigateTo('about')}>ABOUT US</a>
          <a onClick={() => navigateTo('events')} className='disabled '>
            EVENTS <br /> CATALOG <span className="label">SOON</span>
          </a>
          <a onClick={() => navigateTo('releases')} className='disabled right'>
            <span className="label">SOON</span> RELEASE <br /> CATALOG
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
