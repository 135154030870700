import './Home.css';
import React, { useEffect, useState } from 'react';
import ArtistList from '../../components/ArtistList/ArtistList';

const useMouseProgress = () => {
  //initialize the positions
  const [x, setX] = useState<string>('');
  const [y, setY] = useState<string>('');

  // useEffect(() => {
  //   let mouseX = 0,
  //     mouseY = 0,
  //     ballX = 0,
  //     ballY = 0;
  //   const speed = 0.2;
  //   const updateXY = (e: MouseEvent) => {
  //     mouseX = e.pageX;
  //     mouseY = e.pageY;
  //     const distX = mouseX - ballX;
  //     const distY = mouseY - ballY;
  //     ballX = ballX + distX * speed;
  //     ballY = ballY + distY * speed;
  //     setX(ballX + 'px');
  //     setY(ballY + 'px');
  //   };
  //   window.addEventListener('mousemove', updateXY);
  //   return () => {
  //     window?.removeEventListener('mousemove', updateXY);
  //   };
  // }, [setX, setY]);

  return [x, y];
};

const Home: React.FC = () => {
  const [x, y] = useMouseProgress();

  return (
    <main>
      <div className="ball" style={{ top: y, left: x }}>
      <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
        <path fill="#fff" d="M44.8,-77.1C58.1,-69.9,69.1,-58.1,77.5,-44.4C85.9,-30.8,91.8,-15.4,91.6,-0.1C91.5,15.2,85.4,30.5,77.1,44.3C68.7,58.1,58.2,70.4,45,78.7C31.8,87,15.9,91.4,-0.1,91.5C-16.1,91.6,-32.2,87.6,-44.9,79C-57.6,70.4,-67,57.2,-74.6,43.3C-82.3,29.4,-88.2,14.7,-89.4,-0.7C-90.7,-16.1,-87.3,-32.3,-79,-45.1C-70.7,-57.9,-57.5,-67.3,-43.5,-74.2C-29.5,-81.1,-14.8,-85.4,0.5,-86.2C15.7,-87.1,31.4,-84.4,44.8,-77.1Z" transform="translate(100 100)" />
      </svg>
      </div>
      <section id="hero" className='flex flex--justify-center  w-100'>
        <div className="hero">
          <div className="hero-logo">
            <img src="/images/global/hero-shape-1.svg" alt="" />
          </div>
          <div className="hero-text">
            <h1>
              Yerevan based booking agency, <br /> record label & event series.
            </h1>
          </div>
          <div className="hero-icon flex flex--justify-center ">
            <svg xmlns="http://www.w3.org/2000/svg" width="59" height="61" viewBox="0 0 59 61" fill="none">
              <path
                d="M23.0847 61L24.2941 39.5547L6.41533 51.3629L0 40.1372L19.0882 30.5L0 20.8628L6.41533 9.63715L24.2941 21.4453L23.0847 0H35.8627L34.7059 21.4453L52.5847 9.63715L59 20.8628L39.8592 30.5L59 40.1372L52.5847 51.3629L34.7059 39.5547L35.8627 61H23.0847Z"
                fill="#ffffff"
                opacity={0.4}
              />
            </svg>
          </div>
        </div>
      </section>
      <section id="content">
        <div className="content container">
          <p>
            Syne is here to present Armenian <br /> talent <br />
            <span>worldwide,</span> <br />
            showcase the <br />
            <small>multidisciplinary talents of both local and international artists through our releases and organize local community-driven events.</small>
          </p>
        </div>
      </section>
      <section id="asymmetric-links">
        <div className="asymmetric-links">
          <a href="javascript:void(0);">Current Crew</a>
          {/* <a href="javascript:void(0);">SyNE</a> */}
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="54" viewBox="0 0 16 54" fill="none">
              <path
                d="M7.29289 53.7071C7.68342 54.0976 8.31658 54.0976 8.7071 53.7071L15.0711 47.3431C15.4616 46.9526 15.4616 46.3195 15.0711 45.9289C14.6805 45.5384 14.0474 45.5384 13.6569 45.9289L8 51.5858L2.34314 45.9289C1.95262 45.5384 1.31945 45.5384 0.92893 45.9289C0.538406 46.3195 0.538406 46.9526 0.92893 47.3431L7.29289 53.7071ZM7 -4.37114e-08L7 53L9 53L9 4.37114e-08L7 -4.37114e-08Z"
                fill="white"
              />
            </svg>
          </span>
        </div>
      </section>

      <ArtistList />
    </main>
  );
};

export default Home;
