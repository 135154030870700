import './Events.css';
import React from 'react';

const Events: React.FC = () => {
  return <main>
  <header>
      <div className="catalog-hero container">
          <div className="catalog-hero-inner flex flex--direction__column gap-5">
              <div className="catalog-hero-top flex flex--space-between flex--align-center">
                  <span className="flex flex--direction__column gap-2">
                      <img className='circle' src="/images/global/circle.svg" alt="" />
                        Upcoming
                  </span>
                  <a href="">
                      <img src="/images/global/link-icon.svg" alt="" />
                  </a>
              </div>
              <div className="catalog-hero-banner">
                  <img className='w-100' src="/images/global/catalog-banner.png" alt=""/>
              </div>
              <div className="catalog-hero-bottom flex flex--space-between flex--align-center">
                  <span>Are we <br/> all good?</span>
                  <small className="align-self--end">May 16</small>
              </div>
          </div>
      </div>
      <div className="catalog-gallery container">
          <div className="catalog-gallery-inner flex flex--align-center flex--space-between">
              <div className="catalog-gallery-left flex flex--justify-center w-30">
                  <img className='w-100' src="/images/global/catalog-gallery-left.png" alt=""/>
              </div>
              <div className="catalog-gallery-right flex flex--justify-center w-70">
                  <img className='w-100' src="/images/global/catalog-gallery-right.png" alt=""/>
              </div>
          </div>
      </div>
  </header>
  <main>
      <section id="asymmetric-links">
          <div className="asymmetric-links">
            <a href="javascript:void(0);">Syne</a>
            <a href="javascript:void(0);">Catalog</a>
            <a href="javascript:void(0);">Events </a>
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="54" viewBox="0 0 16 54" fill="none">
                <path
                  d="M7.29289 53.7071C7.68342 54.0976 8.31658 54.0976 8.7071 53.7071L15.0711 47.3431C15.4616 46.9526 15.4616 46.3195 15.0711 45.9289C14.6805 45.5384 14.0474 45.5384 13.6569 45.9289L8 51.5858L2.34314 45.9289C1.95262 45.5384 1.31945 45.5384 0.92893 45.9289C0.538406 46.3195 0.538406 46.9526 0.92893 47.3431L7.29289 53.7071ZM7 -4.37114e-08L7 53L9 53L9 4.37114e-08L7 -4.37114e-08Z"
                  fill="white"
                />
              </svg>
            </span>
          </div>
          <div className="container project-qty flex flex--justify-center gap-1 flex--align-center">
              <span>24</span>
              <p>Projects <br/> Currently Counting</p>
          </div>
      </section>      
      <section id="catalog">
          <div className="catalog-wrapper container">
              <div className="catalog-inner flex flex--space-between">
                  <div className="flex flex--direction__column flex--align-center w-50">
                      <div className="catalog-title flex flex--align-center flex--space-between w-100">
                          <span>CATALOG</span>
                          <img src="/images/global/star.svg" alt="" />
                      </div>
                      <div className="catalog-event-banner w-100">
                          <a href=""><img className='w-100' src="/images/global/catalog-1.png" alt=""/></a>
                          <a href=""><img className='w-100' src="/images/global/catalog-2.png" alt=""/></a>
                          <a href=""><img className='w-100' src="/images/global/catalog-3.png" alt=""/></a>
                      </div>
                  </div>
                  <div className="flex flex--direction__column flex--align-center w-50">
                      <div className="catalog-info flex flex--justify-start  w-100 flex--align-center ">
                         <span>Dates</span>
                         <span>Name</span>
                      </div>
                      <div className="catalog-events-wrap flex flex--direction__column gap-5 w-100">
                          <div className="catalog-events flex">
                              <span className="year">2025</span>
                              <ul>
                                  <li><a href=""><p> May 5</p><span>Gathering at Dilijan’s</span></a></li>
                                  <li><a href=""><p> June 5</p><span>Gathering at Dilijan’s</span></a></li>
                                  <li><a href=""><p> May 6</p><span>We the people exhibition</span></a></li>
                                  <li><a href=""><p> June 24</p><span>Gathering at Dilijan’s</span></a></li>
                                  <li><a href=""><p> May 24</p><span>Gathering at Dilijan’s</span></a></li>
                                  <li><a href=""><p> Jay 27</p><span>We the people exhibition</span></a></li>
                              </ul>
                          </div>
                          <div className="catalog-events flex">
                              <span className="year">2024</span>
                              <ul>
                                  <li><a href=""><p> May 27</p><span>Gathering at Dilijan’s</span></a></li>
                                  <li><a href=""><p> June 31</p><span>We the people exhibition</span></a></li>
                                  <li><a href=""><p> May 10</p><span>Gathering at Dilijan’s</span></a></li>
                                  <li><a href=""><p> June 11</p><span>We the people exhibition</span></a></li>
                                  <li><a href=""><p> May 11</p><span>Gathering at Dilijan’s</span></a></li>
                                  <li><a href=""><p> June 11</p><span>Gathering at Dilijan’s</span></a></li>
                              </ul>
                          </div>
                      </div>
                      
                  </div>
              </div>
          </div>
      </section>  
  </main>
</main>;
};

export default Events;
