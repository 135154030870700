// src/routes/AppRoutes.tsx
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../pages/Home/Home';
import About from '../pages/About/About';
import SingleArtist from '../pages/SingleArtist/SingleArtist';
import Events from '../pages/Events/Events';
import Releases from '../pages/Releases/Releases';
// Import other top-level pages as needed

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" Component={Home} />
      <Route path="about" Component={About} />
      <Route path="artist/:id" Component={SingleArtist} />
      <Route path="events" Component={Events} />
      <Route path="releases" Component={Releases} />
    </Routes>
  );
};

export default AppRoutes;
