import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Artists } from '../../app/constants/Artists';
import { useState, useEffect } from 'react';
import Slider from 'react-slick';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const ArtistList: React.FC = () => {
  
  const navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  const renderMediaLink = (mediaLink: string) => {
    if (mediaLink == 'soundcloud') {
      return (
        <img src="/images/global/soundcloud.svg" alt="" />
      );
    } else if (mediaLink == 'instagram') {
      return (
        <img src="/images/global/instagram.svg" alt="" />
      );
    }
  };

  const handleNavigation = (id: string) => {
    navigate(`/artist/${id}`);
  };

  const artistList = Artists.map(artist => (
    <a className="artists-item flex flex--justify-center" key={artist.name}>
      <div className="top-action">
        {/* <div className="top-label">
          {artist.tags.map((tag, index) => (
            <span key={index}>{tag}</span>
          ))}
        </div> */}
        <div className="top-media">
          {artist.mediaLinks.map((mediaLink, i) => (
            <a href={mediaLink.link} key={i} target="_blank" rel="noreferrer">
              {renderMediaLink(mediaLink.type)}
            </a>
          ))}
        </div>
      </div>
      <div className="artist-img" onClick={() => handleNavigation(artist.name)}>
        <img className='w-100' src={artist.image} alt={artist.name + ' Image'} />
      </div>
      <div className="bottom-action" onClick={() => handleNavigation(artist.name)}>
        <span>
          <p>{artist.name}</p>
          <button>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
              <path d="M6.33333 1.5H30.5V25.6667M30.5 1.5L1.5 30.5L30.5 1.5Z" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </button>
        </span>
      </div>
    </a>
  ));
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplaySpeed: 10000,
    infinite: true,
    autoplay: true,
    centerMode: true,
    centerPadding: "0rem",
    arrows: true, 
  };
  return (
    <section id="artists" className='flex flex--justify-center'>
      <div className="artists container flex flex--justify-center flex--align-center flex--wrap">
        { width > 1099 ? artistList :
          <Slider {...settings}>
          {artistList}
          </Slider>
        }
        </div>
    </section>
  );
};

export default ArtistList;
