  import './Footer.css';
import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className='flex flex--justify-center'>
      <div className="footer flex flex--justify-center flex--align-center">
        <div className="footer-container container flex flex--wrap flex--space-between">
          <div className="footer-left">
            <div className="logo">
              <img src="images/global/hero-shape-1.svg" alt="" />
            </div>
          </div>
          <div className="footer-right">
            <div>
              <p>
                Syne is dedicated to promoting Armenian talent on a global <br />
                scale, highlighting the diverse skills of both local and <br />
                international artists through our releases and hosting <br />
                community-focused events. <br />
                <br />
                <br />
                Our team is ready to answer any questions about potential  <br />
                collaborations. Don’t hesitate to drop 
                us a line at: <a href="mailto:marina@syne.am">marina@syne.am</a>
              </p>
              <div className="footer-links flex flex--space-between flex--align-center w-100">
            {/* <div className="name">:/syne/</div> */}
            <div className="links flex flex--align-center">
              <a href="https://www.instagram.com/syne_crew/" target="_blank" rel="noreferrer noopener">
                <img className='w-100' src="/images/global/instagram.svg" alt="" />
              </a>
              <a href="https://soundcloud.com/syne-crew" target="_blank" rel="noreferrer noopener">
              <img className='w-100' src="/images/global/soundcloud.svg" alt="" />
              </a>
              <a href="https://syne-crew.bandcamp.com/" target="_blank" rel="noreferrer noopener">
                <img className='w-100' src="/images/global/bandcamp.svg" alt="" />
              </a>
            </div>
          </div>
            </div>

          </div>
          
        </div>
      </div>
    </footer>
  );
};

export default Footer;
