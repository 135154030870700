import './About.css';
import React from 'react';

const About: React.FC = () => {
  return <main>
  <header>
      <div className="about-hero flex  flex--align-center flex--direction__column">
          <div className="about-hero-overlay"></div>
          <div className="about-hero-top-text flex flex--direction__column align-self--start gap-1">
              <span>ARTIST</span>
              <span>COLLECTIVE</span>
          </div>
          <div className="about-hero-logo">
              <img src="/images/global/hero-shape-1.svg" alt=""/>
          </div>
          <div className="about-hero-bottom-text flex flex--direction__column align-self--end flex--end  gap-1">
              <span>EVENT SERIES</span>
              <span>RECORD LABEL</span>
          </div>
      </div>
  </header>
  <main>
      <div className="about-asymmetric-text container">
          <p>
              <span className="align-self--end">Syne is here</span>
              <small>to present Armenian talent <i>worldwide,</i></small> 
              <span className="align-self--end">showcase the multidisciplinary talents of <i>both</i> </span>
              <small> <i>local and international artists</i> through our releases</small>
              <small>and organize</small>
              <span className="align-self--center">local community-driven events.</span>
          </p>
      </div>
      <div className="about-content container flex flex--direction__column gap-6 flex--align-center">
          <div className="flex flex--start gap-3">
            <div className="w-30">
              <p>Short Story</p>
            </div>
            <div className="w-70">
              <span>
              Syne is a booking agency, record label, and an event series dedicated to promoting artistic talent and diversity in the music industry. Our aim is to provide a creative platform where artists can connect with audiences worldwide.
              </span>
            </div>
          </div>
          <div className="flex flex--start gap-3">
              <div className="w-30">
                  <p>Booking Agency</p>
              </div>
              <div className="w-70">
              <span>
              With a multi-disciplinary musical approach, our artists seamlessly integrate various influences, creating dynamic and engaging performances that cater to diverse audience preferences.
              </span>
              </div>
          </div>
          <div className="flex flex--start gap-3">
              <div className="w-30">
                  <p>Record Label</p>
              </div>
              <div className="w-70">
              <span>
                We are an independent multi-disciplinary record label with a vision of modern electronic music, focused on supporting artists of diverse talents.
              </span>
              </div>
          </div>
          <div className="flex flex--start gap-3">
              <div className="w-30">
                  <p>Event Series</p>
              </div>
              <div className="w-70">
              <span>
              Syne, driven by music enthusiasts, is deeply rooted in the local electronic music scene.With a strong background of promoting events and running collectives such as Loopdeville Festival and Poligraf Club, we continue our mission to develop the electronic music scene in the capital of Yerevan by bringing in more of the best and highlighting both local and international talent.As an independent collective, we are committed to shining a light on passionate people and communities by creating a safe space where everyone can find their own place for self-expression and connect with a like-minded community to collaborate and share joy and inspiration. Our events present a seamless blend of audiovisual multidisciplinary components, creating a dynamic and interactive experience that transcends traditional boundaries.
              </span>
              </div>
          </div>
       </div>
  </main>
</main>
  ;
};

export default About;
