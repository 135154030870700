import React from 'react';
import { Provider } from 'react-redux';
import store from './app/reducers/store';
import AppRoutes from './routes/AppRoutes';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import ScrollToTop from './components/ScrollToTop';
import { BrowserRouter as Router } from 'react-router-dom';

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <Router>
        <ScrollToTop />
        <Header />
        <AppRoutes />
        <Footer />
      </Router>
    </Provider>
  );
};

export default App;
