export const Artists = [
  {
    id: '1',
    name: 'Yhii',
    image: '/images/artists/yhii-2.png',
    tags: ['DJ', 'Producer'],
    shortBio: 'Yhii is a DJ and producer from Yerevan and a founder of Back&forth, resident artist at DIARIES collective. With a mystical ability to create a direct and pure connection with his audience, Davit is primarily focused on sharp, minimalistic and hypnotising sounds.',
    longBio: `Originating from the heart of Yerevan, Yhii rises as a notable figure in the international electronic music scene. Founding Back&forth and serving as a resident artist at Diaries collective, Yhii brings a diverse cultural influence to his creations. Beginning his musical journey as Cloistral during teenage years, Davit showcased an innate understanding of sound, navigating through a spectrum of emotions with unconventional skill. With over a decade of experience, Yhii has solidified his status as a cornerstone of Yerevan's underground scene. Steering clear of the ordinary, his music is characterized by sharp, minimalist, and hypnotic sounds, transporting the audience in a state of total flow. Continuously exploring new avenues of expression, Yhii remains at the forefront of innovation, resonating with listeners across the globe.`,
    podcasts: [
      `https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1787641585&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false&visual=true`,
      `https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1584898331&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false&visual=true`
    ],
    videoPodcasts: [],
    mediaLinks: [
      {
        type: 'soundcloud',
        link: 'https://soundcloud.com/yhii',
      },
      {
        type: 'instagram',
        link: 'https://www.instagram.com/yhii.ii/',
      },
    ],
  },
  {
    id: '5',
    name: 'Is',
    image: '/images/artists/is.png',
    tags: ['DJ', 'Producer'],
    shortBio: `Is found solace in the universal language of music, effectively transcending linguistic barriers to evoke deep emotions in her listeners. Is' DJ sets strike a perfect balance of micro-detailed dynamics, seamlessly interwoven with the driving force of powerful drums.`,
    longBio: `Is is an electronic music producer and DJ based in Armenia. Her entry into the field occurred with live performances of ambient music, creating skillfully crafted soundscapes that carried listeners to new territories. Her passion for crafting stories led her to take on a journey into dance floor-oriented music. As a resident DJ at Club Poligraf, she has smoothly developed and displayed her signature minimalistic, detail-focused, dynamic sound. Her DJ sets and tracks strike a balance between keen and subtle, intricate sounds and the driving force of powerful drums, seamlessly interwoven together.`,
    podcasts: [
      `https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1670113581&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false&visual=true`,
      `https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1409621965&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false&visual=true`
    ],
    videoPodcasts: [],
    mediaLinks: [
      {
        type: 'soundcloud',
        link: 'https://soundcloud.com/is-iiisssss',
      },
      {
        type: 'instagram',
        link: 'https://www.instagram.com/arpyy_h/',
      },
    ],
  },
  {
    id: '2',
    name: 'Fluctt',
    image: '/images/artists/aram.png',
    tags: ['DJ', 'Producer'],
    shortBio: `Inspired early by the rhythmic soul of Hip Hop, Fluctt has developed a unique sound within the realms of techno and underground music. Fluctt's DJ sets blend tribal groove and hypnotic techno, offering a constantly changing sonic journey that reflects his stage name's meaning-constant fluctuation.`,
    longBio: `Raised in an artistically rich family, Fluctt was inspired early by the rhythmic soul of Hip Hop, which led him to develop a unique sound within the realms of techno and underground music. Based in Yerevan, Armenia, he has played a key role in shaping the local music scene through his involvement with Cyber Folk, an educational initiative he co-founded that provides training in DJing and music production while fostering a community for emerging artists. Fluctt's DJ sets blend tribal groove and hypnotic techno, offering a constantly changing sonic journey that reflects his stage name's meaning—constant fluctuation. He is known for breaking conventional musical boundaries to create a distinctive sound, filled with emotion and varying moods. A regular at major festivals and esteemed clubs like Poligraf and TheOffice, Fluctt has also collaborated with notable DJs such as Setaoc Mass and Bjarki, continuously pushing the limits of his artistry and refining his unique style.`,
    podcasts: [
      `https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1458811021&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false&visual=true`,
      `https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1542028630&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false&visual=true`,
      `https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1670709129&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false&visual=true`
    ],
    videoPodcasts: [],
    mediaLinks: [
      {
        type: 'soundcloud',
        link: 'https://soundcloud.com/fluctt',
      },
      {
        type: 'instagram',
        link: 'https://www.instagram.com/fluctt__/',
      },
    ],
  },
  {
    id: '3',
    name: 'Hopdop',
    image: '/images/artists/tst.png',
    tags: ['DJ', 'Producer'],
    shortBio: `Hopdop’s unique blend of energy and mystique has captivated dance floors for over a decade. His sets are infused with unique tribal grooves that keep dance floors pounding. From high-energy and euphoric techno to deeper and more introspective genres, his sets take listeners on an unforgettable journey.`,
    longBio: `Davit Sukiasyan, primarly known as Hopdop, is a force to be reckoned with in the world of techno. His unique blend of energy and mystique has captivated dance floors for over a decade. Originally from Yerevan, he quickly became a key player in the Armenian electronic music community, hosting underground parties that developed a cult following. Davit is one of the co-founders of Loopdeville festival, a beloved fixture on the techno calendar, and has played a pivotal role in launching Poligraf. Davit's sets and produced music under his other collaborative projects, such as Kamarama, are also renowned. His sets are infused with unique tribal grooves that keep dance floors pounding. Hopdop’s ability to read a room and create a range of moods makes him a highly sought-after artist. From high-energy and euphoric techno to deeper and more introspective genres, his sets take listeners on an unforgettable journey.`,
    podcasts: [
      `https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1604284914&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false&visual=true`,
      `https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1247742175&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false&visual=true`,
      `https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1388398285&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false&visual=true`
    ],

    videoPodcasts: [],
    mediaLinks: [
      {
        type: 'soundcloud',
        link: 'https://soundcloud.com/t_story_matters',
      },
      {
        type: 'instagram',
        link: 'https://www.instagram.com/hopdop_d/',
      },
    ],
  },
  {
    id: '4',
    name: 'Your friend, daao',
    image: '/images/artists/dao.png',
    tags: ['DJ', 'Producer'],
    shortBio: `With his music spanning various moods and genres, Your friend, daao’s each performance whether he is playing live or a dj set are both groovy and well-crafted and the anticipation of what to expect is part of the experience. Your friend, daao explores life through creation and endeavours to pinpoint the perfect sound for our days and nights. An Armenian musician, designer, and creative director, stands as a prominent figure in Yerevan's and Lisbon’s contemporary scene. With his music spanning various moods and genres, Your friend, daao’s each performance whether he is playing live or a dj set are both groovy and well-crafted and the anticipation of what to expect is part of the experience. With an unquenchable thirst for artistic growth, Your friends’ journey has led to captivating performances across prestigious venues, leaving an indelible impression on every stage graced. Your friend, daao's artistic vision continues to evolve, beckoning audiences into a world where emotions are masterfully intertwined with art, and creativity knows no bounds. Your friend, daao explores life through creation and endeavours to pinpoint the perfect sound for our days and nights.`,
    longBio: `An Armenian musician, designer, and creative director, stands as a prominent figure in Yerevan's and Lisbon’s contemporary scene. With his music spanning various moods and genres, Your friend, daao’s each performance whether he is playing live or a dj set are both groovy and well-crafted and the anticipation of what to expect is part of the experience. He had been in the line ups along side with DVS1, Dusty Kid, Young Marco, Masalo, Soichi Terada, Viken Arman, Kareem Ali and many more. Recognized for collaborative prowess, Your friend, daao's creative collaborations have garnered admiration and praise from fellow artists and audiences alike. His passion for connecting with like-minded creators has fostered an artistic community that thrives on innovation and exploration. With an unquenchable thirst for artistic growth, Your friends’ journey has led to captivating performances across prestigious venues, leaving an indelible impression on every stage graced. Your friend, daao's artistic vision continues to evolve, beckoning audiences into a world where emotions are masterfully intertwined with art, and creativity knows no bounds. Your friend, daao explores life through creation and endeavours to pinpoint the perfect sound for our days and nights.`,
    podcasts: [
      `https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1699922835&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false&visual=true`,
      `https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1633420872&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false&visual=true`
    ],
    videoPodcasts: [],
    mediaLinks: [
      {
        type: 'soundcloud',
        link: 'https://soundcloud.com/masterdaao',
      },
      {
        type: 'instagram',
        link: 'https://www.instagram.com/yourfrienddaao/',
      },
    ],
  },
  
  {
    id: '6',
    name: 'Doubledav',
    image: '/images/artists/double-dave.png',
    tags: ['DJ'],
    shortBio: ``,
    longBio: `Doubledav is a dynamic DJ duo featuring Yhii and Hopdop. They blend sharp, minimalistic sounds with unique primal rhythms, creating a captivating and hypnotic experience. Their high-energy, euphoric sets take listeners on memorable adventures, connecting deeply with audiences through their mystical and introspective styles.`,
    podcasts: [],
    videoPodcasts: [],
    mediaLinks: [
      // {
      //   type: 'soundcloud',
      //   link: 'https://soundcloud.com/yhii',
      // },
      // {
      //   type: 'instagram',
      //   link: 'https://instagram.com/yhii',
      // },
    ],
  },
];
